.work-container {
    margin: 5px;
    padding: 5px;
}
.work-content {
    padding: 0;
}


@media print {
    .work-content {
        padding: 0;
    }
    .work-content h4 {
        margin: 0;
    }

}
