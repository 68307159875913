/* Skills.css */
.skills-container {
    margin: 10px;
    padding: 10px;
}
.skills-content {
    padding: 0;
}

.skills-content ul {
    list-style: none;
    /*padding: 0;*/
}

.skills-content li {
    display: inline-block;
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #759d9a; /* Cor de fundo */
    color: #fff; /* Cor do texto */
    border: 2px solid #759d9a; /* Cor da borda */
    border-radius: 5px; /* Borda arredondada */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.skills-content li:hover {
    background-color: #28A598; /* Cor de fundo ao passar o mouse */
    border-color: #28A598; /* Cor da borda ao passar o mouse */
    color: #fff; /* Cor do texto ao passar o mouse */
}

@media print {
    .skills-content li {
        font-size: 14px;
    }

}
