.App-header {
  background-color: #575151;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
}

.centered-div {
  width: 70%; /* Largura da div para impressão */
  /*max-width: 800px; !* Largura máxima da div *!*/
  margin: 0 auto; /* Centralizar a div horizontalmente */
  box-sizing: border-box; /* Incluir padding e border na largura total */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.conteiner {
  margin: 0;
  padding: 0;
  display: flex;
}
.conteiner h1 {
  color: #2ec2b2;
}
.conteiner h2 {
  color: #1aa496;
}

.info-content {
  flex: 0 0 40%;
}

.career-content {
  flex: 0 0 60%;
}

@media print {
  .centered-div {
    width: 100%; /* Utilizar a largura total para impressão */
    margin: 0; /* Remover margens para impressão */
    box-shadow: none; /* Remover sombra para impressão */
  }
  body {
    background-color: #575151;
  }

  .no-print {
    display: none;
  }
  .info-content {
    flex: 0 0 40%;
  }

  .career-content {
    flex: 0 0 60%;
  }
}

@media screen and (max-width: 768px) {
  .centered-div {
    width: 95%; /* Largura da div para impressão */
    /*max-width: 800px; !* Largura máxima da div *!*/
    margin: 0 auto; /* Centralizar a div horizontalmente */
    box-sizing: border-box; /* Incluir padding e border na largura total */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  .conteiner {
    flex-direction: column;
  }
  .info-content {
    flex: 0 0 100%;
  }

  .career-content {
    flex: 0 0 100%;
  }
}
